import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import BmiCalculator from "./components/BmiCalculator";
import AgeCalculator from "./components/AgeCalculator";
import ClickCounter from "./components/ClickCounter";
import "./App.css";
import TextCarousel from "./components/FunFacts";
import { funfacts } from "./content/funfacts";
import NavigationMenu from "./components/NavigationMenu";
import OpenAIComp from "./components/OpenAi";
import Fairytale from "./components/Fairytale";

const App = () => {
  return (
    <Router>
      <div>
        <NavigationMenu />

        <Routes>
          <Route path="/click" element={<ClickCounter />} />
          <Route path="/age" element={<AgeCalculator />} />
          <Route path="/bmi" element={<BmiCalculator />} />
          <Route path="/open" element={<OpenAIComp />} />
          <Route path="/fairytale" element={<Fairytale />} />
          <Route path="/funfacts" element={<TextCarousel texts={funfacts} />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
/**
 *
 * Login with descope
 * Earn badges the more you click on a thing
 * store where? firebase? localsotorage? jff
 *
 * show tweet link to brag about badges
 */
