import "./styles.css";
import { motion } from "framer-motion";

const cardVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const hue = (h) => `hsl(${h}, 100%, 50%)`;

export function Card({ text, hueA, hueB }) {
  const background = `linear-gradient(306deg, ${hue(hueA)}, ${hue(hueB)})`;

  return (
    <motion.div
      className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <div className="splash" style={{ background }} />
      <motion.div className="card" variants={cardVariants}>
        <span className="card-text">{text}</span>
      </motion.div>
    </motion.div>
  );
}

const food = [
  ["20 days", 340, 10],
  ["34 hours", 20, 40],
  ["34 years", 60, 90],
  ["67890 minutes", 80, 120],
  ["123456 seconds", 100, 140],
  ["average days left 39898", 205, 245],
];

export function FancyView() {
  return food.map(([emoji, hueA, hueB]) => (
    <Card text={emoji} hueA={hueA} hueB={hueB} key={emoji} />
  ));
}

export function NumberPresentation({ number }) {
  return (
    <motion.div
      style={{
        fontSize: "5rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      animate={{
        rotate: [0, -10, 10, -10, 10, 0],
        scale: [1, 1.2, 1.2, 1.2, 1.2, 1],
      }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
      }}
    >
      {number}
    </motion.div>
  );
}
