import React, { useState, useEffect } from "react";
import { TwitterShareButton } from "react-share";

const ClickCounter = () => {
  const [count, setCount] = useState(() => {
    const storedCount = localStorage.getItem("clickCount");
    return storedCount ? parseInt(storedCount) : 0;
  });

  useEffect(() => {
    localStorage.setItem("clickCount", count.toString());
  }, [count]);

  const handleClick = () => {
    setCount(count + 1);
  };

  let imageToShow = null;
  let clicksToNextLevel = 0;
  let level = "";
  let tweetText = "";

  switch (true) {
    case count >= 0 && count < 100:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt={`Next Image: ${100 - count} Clicks`}
        />
      );
      clicksToNextLevel = 100 - count;
      level = "Level 1";
      tweetText = `I reached Level 1 in the Click Counter game! Only ${clicksToNextLevel} clicks to go! 🚀`;
      break;
    case count >= 100 && count < 1000:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt={`Next Image: ${1000 - count} Clicks`}
        />
      );
      clicksToNextLevel = 1000 - count;
      level = "Level 2";
      tweetText = `I reached Level 2 in the Click Counter game! Only ${clicksToNextLevel} clicks to go! 🚀`;
      break;
    case count >= 1000 && count < 5000:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt={`Next Image: ${5000 - count} Clicks`}
        />
      );
      clicksToNextLevel = 5000 - count;
      level = "Level 3";
      tweetText = `I reached Level 3 in the Click Counter game! Only ${clicksToNextLevel} clicks to go! 🚀`;
      break;
    case count >= 5000 && count < 7500:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt={`Next Image: ${7500 - count} Clicks`}
        />
      );
      clicksToNextLevel = 7500 - count;
      level = "Level 4";
      tweetText = `I reached Level 4 in the Click Counter game! Only ${clicksToNextLevel} clicks to go! 🚀`;
      break;
    case count >= 7500 && count < 10000:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt={`Next Image: ${10000 - count} Clicks`}
        />
      );
      clicksToNextLevel = 10000 - count;
      level = "Level 5";
      tweetText = `I reached Level 5 in the Click Counter game! Only ${clicksToNextLevel} clicks to go! 🚀`;
      break;
    case count >= 10000:
      imageToShow = (
        <img
          src={`data:image/png;base64,YOUR_IMAGE_BASE64_DATA_HERE`}
          alt="Congratulations!"
        />
      );
      level = "Max Level";
      tweetText = `I reached the Max Level in the Click Counter game! What an achievement! 🎉`;
      break;
    default:
      break;
  }

  return (
    <div>
      <button onClick={handleClick}>Click me!</button>
      <p>Click count: {count}</p>
      {imageToShow && (
        <>
          <div>{imageToShow}</div>
          <TwitterShareButton title={tweetText} url={window.location}>
            Share on Twitter - {level}
          </TwitterShareButton>
        </>
      )}
    </div>
  );
};

export default ClickCounter;
