import { Button } from "@mui/joy";
import React, { useState } from "react";

const BmiCalculator = () => {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [message, setMessage] = useState("");

  const calculateBmi = () => {
    const h = height / 100; // convert height to meters
    const w = weight;
    const bmi = w / (h * h);
    setBmi(bmi.toFixed(2)); // round off to 2 decimal places

    if (bmi < 18.5) {
      setMessage("You are underweight.");
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      setMessage("You are healthy.");
    } else if (bmi >= 25 && bmi <= 29.9) {
      setMessage("You are overweight.");
    } else if (bmi >= 30) {
      setMessage("You are obese.");
    }
  };

  return (
    <div style={{ padding: "24px" }}>
      <h1>BMI Calculator</h1>
      <label>
        Height (cm):
        <input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </label>
      <br />
      <label>
        Weight (kg):
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
      </label>
      <br />
      <br />
      <Button onClick={calculateBmi}>Calculate BMI</Button>

      <br />
      <br />
      {bmi && (
        <div>
          <p>Your BMI is: {bmi}</p>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default BmiCalculator;
