import React, { useState, useEffect } from "react";

import OpenAI from "openai";
import { Button } from "@mui/joy";
const OK = {
  apiKey: "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",
  dangerouslyAllowBrowser: true, // yeye please be kind :) beer
};
const openai = new OpenAI(OK);

const OpenAIComp = () => {
  const [fact, setFact] = useState("");

  async function getFact() {
    const completion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: "Give me a funfact about the human body" },
      ],
      model: "gpt-3.5-turbo",
    });

    setFact(completion.choices[0].message.content);
  }

  useEffect(() => {
    console.log(4444);
    // main();
  }, []);

  return (
    <div>
      <h1>
        Click the button to get a fun fact about the human body, from
        OpenAi(gpt-3.5-turbo)
      </h1>
      <Button
        onClick={() => {
          getFact();
        }}
      >
        Print funfact
      </Button>
      <p>{fact}</p>
    </div>
  );
};

export default OpenAIComp;
