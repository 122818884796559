import React, { useState } from "react";
import { NumberPresentation } from "../FancyView";

const AgeCalculator = () => {
  const [birthDate, setBirthDate] = useState("");
  const [ageInYears, setAgeInYears] = useState("");
  const [ageInMonths, setAgeInMonths] = useState("");
  const [ageInWeeks, setAgeInWeeks] = useState("");
  const [ageInDays, setAgeInDays] = useState("");
  const [ageInHours, setAgeInHours] = useState("");
  const [ageInMinutes, setAgeInMinutes] = useState("");
  const [ageInSeconds, setAgeInSeconds] = useState("");

  function handleDateChange(event) {
    setBirthDate(event.target.value);
  }

  function calculateAge() {
    const birthTime = new Date(birthDate).getTime();
    const currentTime = new Date().getTime();

    const ageInMs = currentTime - birthTime;
    setAgeInSeconds(Math.floor(ageInMs / 1000));
    setAgeInMinutes(Math.floor(ageInMs / (1000 * 60)));
    setAgeInHours(Math.floor(ageInMs / (1000 * 60 * 60)));
    setAgeInDays(Math.floor(ageInMs / (1000 * 60 * 60 * 24)));
    setAgeInWeeks(Math.floor(ageInMs / (1000 * 60 * 60 * 24 * 7)));
    setAgeInMonths(Math.floor(ageInMs / (1000 * 60 * 60 * 24 * 30.44)));
    setAgeInYears(Math.floor(ageInMs / (1000 * 60 * 60 * 24 * 365)));
  }

  return (
    <div style={{ padding: "24px" }}>
      <h1>Age Calculator</h1>

      <label htmlFor="birthDate">Enter your birth date:</label>
      <input type="date" id="birthDate" onChange={handleDateChange} />

      <button onClick={calculateAge}>Calculate Age</button>
      {ageInSeconds && (
        <div>
          <p>You are {ageInSeconds} seconds old.</p>
          <p>You are {ageInMinutes} minutes old.</p>
          <p>You are {ageInHours} hours old.</p>
          <p>You are {ageInDays} days old.</p>
          <p>You are {ageInWeeks} weeks old.</p>
          <p>You are {ageInMonths} months old.</p>
          <p>You are {ageInYears} years old.</p>
          <NumberPresentation number={ageInHours} />
        </div>
      )}
    </div>
  );
};

export default AgeCalculator;
