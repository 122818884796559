export const funfacts = [
  "The average person spends about 6 months of their lifetime waiting for red traffic lights.",
  "A sneeze travels at a speed of approximately 100 miles per hour (160 km/h).",
  "Humans are the only animals that blush.",
  "The human brain is more active during sleep than it is while watching television.",
  "The average person walks the equivalent of three times around the world in their lifetime.",
  "Your nose can remember 50,000 different scents.",
  "Laughing lowers levels of stress hormones and strengthens the immune system.",
  "The average person spends 6 years of their life dreaming.",
  "Frowning uses more muscles than smiling.",
  "A person's height is determined by their father, and their weight is determined by their mother.",
  "Your heartbeat changes and mimics the music you listen to.",
  "Children laugh about 400 times a day, while adults only laugh about 15 times a day.",
  "Humans are born with 300 bones, but by adulthood, we have 206.",
  "Your brain can't feel pain, so even if you're awake during brain surgery, you won't feel anything.",
  "The tongue is the strongest muscle in the human body.",
  "The average person has about 70,000 thoughts each day.",
  "There are more bacteria in your mouth than there are people in the world.",
  "Your ears never stop hearing, even when you sleep.",
  "Smiling can boost your mood, even if you're faking it.",
  "Human bones are ounce for ounce stronger than steel.",
  "On average, women's hearts beat faster than men's.",
  "Your brain is 73% water.",
  "The strongest muscle in proportion to its size in the human body is the tongue.",
  "The acid in your stomach is strong enough to dissolve razor blades.",
  "The average person will spend six months of their life waiting for red lights to turn green.",
  "Every person's unique smell can be detected by dogs.",
  "The human body contains enough carbon to fill 9,000 pencils.",
  "Kissing burns about 2 calories per minute.",
  "When awake, the human brain produces enough electricity to power a small light bulb.",
  "Your stomach produces a new layer of mucus every two weeks, or else it would digest itself.",
  "Humans shed about 600,000 particles of skin every hour.",
  "The average person falls asleep in 7 minutes.",
  "An average person's annual fast-food intake will contain 12 pubic hairs.",
  "Your body produces enough heat in only 30 minutes to boil half a gallon of water.",
  "The lifespan of a taste bud is about 10 days.",
  "The average human spends 2 weeks of their life kissing.",
  "The human body is estimated to have 60,000 miles of blood vessels.",
  "The surface area of a human lung is equal to a tennis court.",
  "Your body has enough iron in it to make a metal nail 3 inches long.",
  "Your brain keeps developing until your late 40s.",
  "An average person will spend 25 years sleeping.",
  "The human eye can distinguish about 10 million different colors.",
  "A person will die from total lack of sleep sooner than from starvation.",
  "Your body is creating and killing 15 million red blood cells per second.",
  "Humans share 50% of their DNA with bananas.",
  "The strongest muscle in the human body is the masseter (jaw muscle).",
  "Babies are born with 300 bones, but as they grow, some of the bones fuse together.",
  "Your body uses 300 muscles just to balance when you're standing still.",
  "In one day, your blood travels a total of 12,000 miles (19,000 km).",
  "Blinking helps to wash tears over eyeballs, keeping them clean and moist.",
  "Humans are the only animals that shed emotional tears.",
  "The average person spends about 4 years of their life eating.",
  "You are taller in the morning than you are at night.",
  "The human brain is 60% fat.",
  "Your body has enough DNA strands to stretch from the Earth to the Sun and back more than 600 times.",
  "The average person blinks about 17,000 times a day.",
  "The human body produces about 10,000 gallons (37,854 liters) of saliva in a lifetime.",
  "You are born with 300 bones, but by the time you reach adulthood, you only have 206.",
  "The average person will spend 1 year of their life looking for misplaced items.",
  "A human's small intestine is about 22 feet (6.7 meters) long.",
  "A newborn baby has about one cup of blood in its body.",
  "The human brain can read up to 1,000 words per minute.",
  "You are taller when you wake up in the morning than you are at night.",
  "Your body has more than 600 individual muscles.",
  "Your eyes are always the same size from birth, but your nose and ears never stop growing.",
  "The average human body contains enough carbon to fill 9,000 pencils.",
  "Your fingernails grow nearly 4 times faster than your toenails.",
  "Your hair grows about 0.5 inches (1.25 cm) per month.",
  "Your heart pumps about 2,000 gallons (7,571 liters) of blood each day.",
  "The average person spends 6 months of their lifetime waiting for red lights to turn green.",
  "The average person spends 1.5 years in the bathroom.",
  "Your body has over 100,000 miles (160,934 km) of blood vessels.",
  "The average human lifespan is about 2 billion heartbeats.",
  "You blink approximately 20 times per minute.",
  "Your heart weighs about 10 ounces (283 grams).",
  "Humans are the only species that use smiles to communicate non-verbally.",
  "The strongest bone in your body is the femur (thigh bone).",
  "Your liver can regenerate itself completely within 6 months.",
  "Your body has enough fat to produce 7 bars of soap.",
  "Your body gives off enough heat in 30 minutes to bring half a gallon (1.9 liters) of water to a boil.",
  "The average human brain weighs about 3 pounds (1.4 kg).",
  "The average person will walk the equivalent of 5 times around the Earth in their lifetime.",
  "You can burn up to 200 calories by laughing for 15 minutes.",
  "An average person's hair will grow about 590 miles (950 km) in their lifetime.",
  "The average person blinks their eyes about 11 million times a year.",
  "A human sneeze can travel up to 100 miles per hour (160 km/h).",
  "You have about 100,000 hairs on your head.",
  "Your nose can remember about 50,000 different scents.",
  "The human body contains enough sulfur to kill all fleas on an average dog.",
  "Your brain generates about 25 watts of power while you're awake.",
  "The average human body contains enough iron to make a small nail.",
  "Your body has enough phosphorus to make 2,200 match heads.",
  "Your skin weighs twice as much as your brain.",
  "Your body produces about 25,000 quarts (23,658 liters) of saliva in a lifetime.",
  "You can survive without food for about a month, but only about a week without water.",
  "Your ears and nose never stop growing.",
  "The surface area of your lungs is roughly the size of a tennis court.",
  "Your body produces a new layer of skin every 28 days.",
  "Your body produces enough heat in 30 minutes to boil half a gallon (1.9 liters) of water.",
  "The average person will spend 6 months of their life waiting for red lights.",
  "Your body has enough blood vessels to wrap around the Earth about 4 times.",
  "The average person's hair grows about 0.5 inches (1.25 cm) per month.",
  "Your body produces about 10,000 gallons (37,854 liters) of saliva in a lifetime.",
  "Your brain uses about 20% of your body's total oxygen and calorie intake.",
  "Your body has enough iron to make a nail 3 inches long.",
  "Your brain is about 75% water.",
  "You can lose up to 30% of your taste buds during a flight.",
  "Your body has enough carbon to fill 9,000 lead pencils.",
  "The human body can survive for weeks without food, but only a few days without water.",
  "Your brain is the most energy-consuming organ in your body, using up to 20% of your total energy.",
  "Your body is constantly producing new cells. In fact, most of your body's cells are less than 10 years old.",
  "Your brain has the same consistency as tofu.",
  "Your body is made up of about 7 octillion atoms.",
  "Your body produces about 1 to 2 liters of mucus every day.",
  "Your heart can create enough pressure to squirt blood up to 30 feet (9 meters).",
  "The human body has enough fat to make 7 bars of soap.",
  "Your body is taller in the morning than in the evening due to the compression of spinal discs during the day.",
  "Your body is constantly losing and regrowing bone tissue, so that by the time you're 70, your skeleton is entirely new.",
  "Your body can produce 25 million new cells each second.",
  "Your body produces a new layer of skin every 28 days.",
  "Your heart beats about 100,000 times per day, pumping about 2,000 gallons (7,571 liters) of blood.",
  "Your lungs have a surface area roughly the size of a tennis court.",
  "Your eyes can distinguish about 10 million different colors.",
  "Your body contains enough iron to make a nail 3 inches long.",
  "Your body contains enough carbon to make 900 pencils.",
  "Your body has about 60,000 miles (96,560 km) of blood vessels.",
  "Your nose can remember around 50,000 different scents.",
  "Your brain generates about 20 watts of electrical power, enough to power a light bulb.",
  "Your body is made up of about 7 octillion atoms.",
  "Your heart can create enough pressure to squirt blood up to 30 feet (9 meters).",
  "Your body produces about 1 to 2 liters of mucus every day.",
  "Your body contains enough fat to make 7 bars of soap.",
  "Your body is taller in the morning than in the evening due to the compression of spinal discs during the day.",
  "Your body is constantly losing and regrowing bone tissue, so that by the time you're 70, your skeleton is entirely new.",
  "Your body can produce 25 million new cells each second.",
  "Your body produces a new layer of skin every 28 days.",
  "Your heart beats about 100,000 times per day, pumping about 2,000 gallons (7,571 liters) of blood.",
  "Your lungs have a surface area roughly the size of a tennis court.",
  "Your eyes can distinguish about 10 million different colors.",
  "Your body contains enough iron to make a nail 3 inches long.",
  "Your body contains enough carbon to make 900 pencils.",
  "Your body has about 60,000 miles (96,560 km) of blood vessels.",
  "Your nose can remember around 50,000 different scents.",
  "Your brain generates about 20 watts of electrical power, enough to power a light bulb.",
  "Your body is made up of about 7 octillion atoms.",
  "Your heart can create enough pressure to squirt blood up to 30 feet (9 meters).",
  "Your body produces about 1 to 2 liters of mucus every day.",
  "Your body contains enough fat to make 7 bars of soap.",
  "Your body is taller in the morning than in the evening due to the compression of spinal discs during the day.",
  "Your body is constantly losing and regrowing bone tissue, so that by the time you're 70, your skeleton is entirely new.",
  "Your body can produce 25 million new cells each second.",
  "Your body produces a new layer of skin every 28 days.",
  "Your heart beats about 100,000 times per day, pumping about 2,000 gallons (7,571 liters) of blood.",
  "Your lungs have a surface area roughly the size of a tennis court.",
  "Your eyes can distinguish about 10 million different colors.",
  "Your body contains enough iron to make a nail 3 inches long.",
  "Your body contains enough carbon to make 900 pencils.",
  "Your body has about 60,000 miles (96,560 km) of blood vessels.",
  "Your nose can remember around 50,000 different scents.",
  "Your brain generates about 20 watts of electrical power, enough to power a light bulb.",
  "Your body is made up of about 7 octillion atoms.",
  "Your heart can create enough pressure to squirt blood up to 30 feet (9 meters).",
  "Your body produces about 1 to 2 liters of mucus every day.",
  "Your body contains enough fat to make 7 bars of soap.",
  "Your body is taller in the morning than in the evening due to the compression of spinal discs during the day.",
  "Your body is constantly losing and regrowing bone tissue, so that by the time you're 70, your skeleton is entirely new.",
  "Your body can produce 25 million new cells each second.",
  "Your body produces a new layer of skin every 28 days.",
  "Your heart beats about 100,000 times per day, pumping about 2,000 gallons (7,571 liters) of blood.",
  "Your lungs have a surface area roughly the size of a tennis court.",
  "Your eyes can distinguish about 10 million different colors.",
  "Your body contains enough iron to make a nail 3 inches long.",
  "Your body contains enough carbon to make 900 pencils.",
  "Your body has about 60,000 miles (96,560 km) of blood vessels.",
  "Your nose can remember around 50,000 different scents.",
  "Your brain generates about 20 watts of electrical power, enough to power a light bulb.",
  "Your body is made up of about 7 octillion atoms.",
  "Your heart can create enough pressure to squirt blood up to 30 feet (9 meters).",
  "Your body produces about 1 to 2 liters of mucus every day.",
  "The average person spends six months of their life waiting for red lights to turn green.",
  "On average, a person will spend a total of 25 years asleep.",
  "The world's oldest piece of chewing gum is over 9,000 years old.",
  "A sneeze travels out of your mouth at over 100 miles per hour.",
  "Coca-Cola was originally green.",
  "You are born with 300 bones, but by the time you are an adult you only have 206.",
  "The average person's left hand does 56% of the typing.",
  "The elephant is the only animal with four knees.",
  "It's impossible to sneeze with your eyes open.",
  "A crocodile cannot stick its tongue out.",
  "Butterflies taste with their feet.",
  "The average person laughs 10 times a day.",
  "An ostrich's eye is bigger than its brain.",
  "Starfish don't have brains.",
  "Mosquitoes are attracted to people who have recently eaten bananas.",
  "Penguins can jump 6 feet in the air.",
  "A duck's quack doesn't echo, and nobody knows why.",
  "The shortest war in history lasted 38 to 45 minutes.",
  "Banging your head against a wall burns 150 calories an hour.",
  "A snail can sleep for three years.",
  "The world's oldest known recipe is for beer.",
  "Ants never sleep.",
  "Bees have five eyelids.",
  "The average person has over 1,460 dreams a year.",
  "The average person swallows eight spiders in their lifetime.",
  "It's physically impossible for pigs to look up into the sky.",
  "The 3 most common languages in the world are Mandarin Chinese, Spanish, and English.",
  "There are more plastic flamingos in the world than real flamingos.",
  "Astronauts can't burp in space.",
  "Cats have over 100 vocal sounds, while dogs only have about 10.",
  "The average person spends 6 months of their life sitting at red lights.",
  "The average human brain has about 70,000 thoughts per day.",
  "The average human body carries about 4 pounds of bacteria.",
  "Honey never spoils.",
  "An octopus has three hearts.",
  "A cockroach can live for a week without its head before it starves to death.",
  "The shortest war in history was between Zanzibar and England in 1896. Zanzibar surrendered after 38 minutes.",
  "The average person blinks about 20,000 times a day.",
  "The average person will spend about six months of their life waiting for red lights to turn green.",
  "The average person will spend about two weeks kissing in their lifetime.",
  "The average person will spend about six months waiting for trains.",
  "The average person will spend about 25 years asleep.",
  "The average person will spend about three years in the bathroom.",
  "The average person will spend about six months queuing.",
  "The average person will spend about one year looking for lost items.",
  "The average person will spend about four years eating.",
  "The average person will spend about one year in conversation.",
  "The average person will spend about three years on social media.",
  "The average person will spend about four years driving a car.",
  "The average person will spend about five years waiting in lines.",
  "The average person will spend about 38 days laughing.",
  "The average person will spend about three years in meetings.",
  "The average person will spend about six months complaining.",
  "The average person will spend about 10 years watching TV.",
  "The average person will spend about three years on vacations.",
  "The average person will spend about 92 days on the toilet.",
  "The average person will spend about six months shaving.",
  "The average person will spend about two weeks kissing.",
  "The average person will spend about two years on the phone.",
  "The average person will spend about one year looking for things they've lost.",
  "The average person will spend about four years eating.",
  "The average person will spend about 25 years asleep.",
  "The average person will spend about 15 weeks doing housework.",
  "The average person will spend about three years in a car.",
  "The average person will spend about six months waiting for red lights to turn green.",
  "The average person will spend about two years on the internet.",
  "The average person will spend about 30 days brushing their teeth.",
  "The average person will spend about six months queuing.",
  "The average person will spend about four years daydreaming.",
  "The average person will spend about five years on social media.",
  "The average person will spend about two years in a bad mood.",
  "The average person will spend about three years in meetings.",
  "The average person will spend about six months trying to fall asleep.",
  "The average person will spend about 15 days kissing.",
  "The average person will spend about 92 days on the toilet.",
  "The average person will spend about two weeks shaving.",
  "The average person will spend about four years doing laundry.",
  "The average person will spend about two weeks waiting for a train or bus.",
  "The average person will spend about six months waiting in line.",
  "The average person will spend about two years watching commercials.",
  "The average person will spend about two years reading books.",
  "The average person will spend about three years exercising.",
  "The average person will spend about six months eating breakfast.",
  "The average person will spend about three months on hold.",
  "The average person will spend about two weeks looking for misplaced items.",
  "The average person will spend about three months doing laundry.",
  "The average person will spend about two weeks shaving their legs.",
  "The average person will spend about two years commuting to work.",
  "The average person will spend about six months waiting for websites to load.",
  "The average person will spend about two years getting sick.",
  "The average person will spend about 11 years working.",
  "The average person will spend about two years cooking.",
  "The average person will spend about three months arguing.",
  "The average person will spend about four years worrying.",
  "The average person will spend about three months doing housework.",
  "In 2017, more people were killed from injuries caused by taking a selfie than by shark attacks.",
  "The average person walks the equivalent of three times around the world in a lifetime.",
  "The fear of Friday the 13th is called paraskevidekatriaphobia.",
  "A baby octopus is about the size of a flea when it is born.",
  "Bananas are berries, but strawberries are not.",
  "Humans are the only species that can cry emotional tears.",
  "The average person will spend about 25 years of their life sleeping.",
  "The human brain is more active during sleep than it is while watching television.",
  "Humans and giraffes have the same number of neck vertebrae.",
  "The strongest muscle in the human body is the masseter (jaw) muscle.",
  "The human heart can create enough pressure to squirt blood up to 30 feet.",
  "Humans share about 50% of their DNA with bananas.",
  "The average person walks the equivalent of three times around the world in a lifetime.",
  "Your taste buds have a lifespan of about 10 to 14 days.",
  "Humans are the only animals capable of drawing straight lines.",
  "The smell of freshly-cut grass is actually a plant distress call.",
  "Humans are the only primates that don't have pigment in the palms of their hands.",
  "The human eye can distinguish about 10 million different colors.",
  "On average, a person will spend about six months of their life waiting for red lights to turn green.",
  "Fingernails grow nearly four times faster than toenails.",
  "The average person will eat around 70,000 meals in their lifetime.",
  "Humans are the only animals that blush.",
  "There are more bacteria in your mouth than there are people in the world.",
  "The world's oldest known recipe is for beer.",
  "Laughter can increase the production of antibodies and activate immune cells, improving the body's resistance to disease.",
  "The human brain can process information as fast as 120 meters per second.",
  "Your brain uses 20% of the total oxygen and blood in your body.",
  "The human body produces about 1 to 1.5 quarts of saliva each day.",
  "Humans and dogs are the only species known to seek visual cues from another individual's eyes.",
  "Your stomach's digestive acids are strong enough to dissolve zinc. Fortunately for us, the cells in the stomach lining renew so quickly that the acids don't have time to dissolve it.",
  "The human body has more than 650 muscles.",
  "Humans are the only mammals capable of consciously delaying orgasm.",
  "The strongest bone in your body is the femur.",
  "The human nose can remember 50,000 different scents.",
  "The average person will spend about four years of their life looking at their cell phone.",
  "Your brain is about 73% water.",
  "Humans have a unique fingerprint and tongue print.",
  "Humans are the only animals that can throw objects accurately and at great speed.",
  "Your body is constantly replacing old cells with new ones. In about seven years, your body will have completely new cells.",
  "The longest recorded hiccuping spree lasted 68 years.",
  "Humans are the only animals that can consciously delay gratification.",
  "The human skeleton renews itself entirely every 10 years.",
  "If the human brain were a computer, it could perform 38 thousand trillion operations per second.",
  "Your body has enough iron in it to make a small nail.",
  "Humans have a natural instinct to yawn when they see someone else yawn.",
  "The average person will spend about six months of their life waiting for red lights to turn green.",
  "The human brain is capable of holding up to 2.5 petabytes of information.",
  "The acid in your stomach is strong enough to dissolve razor blades.",
  "Humans are the only animals that can recognize themselves in a mirror.",
  "The world's population is expected to reach 9.7 billion by 2050.",
  "The average person will spend about 13 years of their life at work.",
  "Humans have been on Earth for only a tiny fraction of the planet's existence—about 0.004% of Earth's 4.5-billion-year history.",
  "OK",
];
