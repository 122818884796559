import React, { useState, useEffect } from "react";

const TextCarousel = ({ texts }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(
    Math.floor(Math.random() * texts.length)
  );

  const [speachEnabled, setSpeachEnabled] = useState(false);

  const newFact = () => {
    const index = Math.floor(Math.random() * texts.length);
    setCurrentTextIndex(index);
    if (speachEnabled) {
      const utterThis = new SpeechSynthesisUtterance(texts[index]);
      utterThis.lang = "en-US";
      const synth = window.speechSynthesis;
      synth.speak(utterThis);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      if (event.keyCode === 32) {
        newFact();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [texts.length, newFact]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div>
      <div style={{ position: "absolute" }}>
        Click screen or space bar to change fact
      </div>
      <div class="speach" style={{ position: "absolute", right: 0 }}>
        <p>
          {currentTextIndex - 1}/{texts.length}
        </p>
        <label for="peas">Check to enable speach</label>
        <input
          type="checkbox"
          name="speach"
          id="speach"
          onChange={(event) => {
            setSpeachEnabled(event.target.checked);
          }}
        />
      </div>
      <div
        onClick={newFact}
        className="funfact"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: getRandomColor(),
        }}
      >
        <h1 style={{ textAlign: "center" }}>{texts[currentTextIndex]}</h1>
      </div>
    </div>
  );
};

export default TextCarousel;
