import React, { useState, useEffect } from "react";

import OpenAI from "openai";
import { Button, CircularProgress } from "@mui/joy";
import styled from "styled-components";
const OK = {
  apiKey: "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",
  dangerouslyAllowBrowser: true, // yeye please be kind :) beer
};
const synth = window.speechSynthesis;
const openai = new OpenAI(OK);

const ButtonBar = styled.div`
  button {
    margin: 4px;
  }
`;

const Fairytale = () => {
  const [tale, setTale] = useState(
    "Det er ikke noe lastet, klikk på knappene over"
  );

  const [allTales, setAllTales] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getTale(words) {
    if (loading) {
      alert(
        "Please wait for the one you requested to finish loading from the super duper openAI!"
      );
      return null;
    }
    setLoading(true);
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "Gi meg et et eventyr som er for gutter mellom 3 og 10 år. " +
            "Det må være spennende og alltid ha en positiv slutt. " +
            "Det burde innehlde litt lærdom. " +
            "Det må være på cirka  " +
            words +
            ". Eller ta tre minutter å lese. " +
            "Handlingen i eventyret må være satt i moderne tid og gjerne innholde ipads og iphones.",
        },
      ],
      model: "gpt-3.5-turbo",
    });
    setLoading(false);
    const newOne = completion.choices[0].message.content;
    // const newOne = "ive me a bed time feiry tale for childre";
    setTale(newOne);
    setAllTales([newOne, ...allTales]);
  }

  function readTale(text) {
    const utterThis = new SpeechSynthesisUtterance(text);
    // utterThis.voice =
    utterThis.rate = 0.6;
    utterThis.pitch = 1;
    utterThis.lang = "nb-NO";
    synth.speak(utterThis);
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>Eventyr</h1>
      <h3>For gutter 3-10 gamle</h3>
      <ButtonBar>
        <Button
          onClick={() => {
            getTale("50");
          }}
        >
          Lag eventyr
        </Button>
        <br />
        <br />
        <Button
          style={{ backgroundColor: "green" }}
          onClick={() => {
            synth.cancel();
            setTimeout(() => {
              readTale(tale);
            }, 500);
          }}
        >
          Les det siste eventyret fra start
        </Button>
        <Button
          style={{ backgroundColor: "red" }}
          onClick={() => {
            synth.pause();
          }}
        >
          Pause
        </Button>
        <Button
          style={{ backgroundColor: "lightgreen", color: "black" }}
          onClick={() => {
            synth.resume();
          }}
        >
          Fortsett
        </Button>
      </ButtonBar>
      <br />
      {loading && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <p>laster eventyr...</p>
        </div>
      )}
      <section>
        {allTales.map((theTale, index) => {
          return (
            <p style={{ lineHeight: 2 }} key={index}>
              {theTale}
              <br />
              <br />
              <Button
                onClick={() => {
                  synth.cancel();
                  setTimeout(() => {
                    readTale(theTale);
                  }, 500);
                }}
              >
                Les denne
              </Button>
              <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br /> <br />
              <br />
              <br />
            </p>
          );
        })}
      </section>
    </div>
  );
};

export default Fairytale;
