import React from "react";
import styled from "styled-components";

const NavContainer = styled.nav`
  background-color: #1976d2;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
`;
const NavLogo = styled.a`
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
`;

const NavMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavMenuItem = styled.li`
  margin-right: 10px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const NavigationMenu = () => {
  return (
    <NavContainer>
      <NavLogo href="/">LIFEMOCK</NavLogo>
      <NavMenu>
        <NavMenuItem>
          <NavLink href="/age">Age</NavLink>
        </NavMenuItem>
        <NavMenuItem>
          <NavLink href="/bmi">Bmi</NavLink>
        </NavMenuItem>
        {/* <NavMenuItem>
          <NavLink href="/click">Click</NavLink>
        </NavMenuItem> */}
        <NavMenuItem>
          <NavLink
            title="Click screen or space bar to change fact"
            href="/funfacts"
          >
            Funfacts
          </NavLink>
        </NavMenuItem>
      </NavMenu>
    </NavContainer>
  );
};

export default NavigationMenu;
